import React, { useState, useEffect }  from 'react';
import List from '@mui/material/List';

import {useLocation } from "react-router-dom";
import '../css/Search.css'
import Form from 'react-bootstrap/Form';
import SearchCard from './SearchCard';
 function SearchResultMobile() {
  const location =  useLocation();


    const [submitted, setSubmitted] = useState('');
    const [models, setModels] = useState([]);
    const [text, setText] = useState("");
    //const isApple = ['iPhone', 'iPad', 'iPod', 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator',].includes(navigator.platform)
    async function handleSubmit(e) {
        console.log('1');
        e.preventDefault();
        setSubmitted(text);
        setText("");
        console.log('2');
        console.log('text = ' + text);
        const wait = await searchAPI()   
        console.log('6');    
        console.log(wait);  
      }
      function handleChange(e) {
        setText(e.target.value);
      }

      const searchAPI = async () => { 
        let chatModel = {
            word: text
        }
        console.log(chatModel);
        const req = JSON.stringify(chatModel);
        console.log(req);
        console.log('3');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: req
        };
        
        try {
            const response = await fetch('https://aipalbot-chat.azurewebsites.net/search',
         requestOptions);
            const data = await response.json();
            console.log('4');           
            console.log(data)
            setModels(data)
            console.log('5');  
        } catch (e) {
            console.log("unable to parse - fetch chat");
        }
        setText(submitted);
      };

  return (
    <div className="search-box">    
    <div>
    <Form className="d-flex"   onSubmit={handleSubmit}>
<br>
</br>
            <Form.Control
              type="search"
              value={text}
              placeholder=" Search data from millions of robots"
              className="me-2"
              aria-label="Search"
              onChange={handleChange} 
            />
            <span className="buttons">

       
            </span>
        </Form>
      

        </div>
   
    <br></br>
        <List sx={{ width: '95%', maxWidth: 390, bgcolor: 'background.paper', padding:'1% 1% 1% 1%' }}>
          {models&&models.map((model, index) => {
            return (<SearchCard searchItem={model} />)
})}
        </List>
        </div>
        
  );

 
}


export default SearchResultMobile;