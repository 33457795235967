
import './App.css';
import Layout from './search/Layout';
import DetachWindow from './search/DetachWindow';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LayoutMobile from './search/LayoutMobile';



function App() {
    
  return (
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Layout />} />
    <Route path='/mobile' element={<LayoutMobile />} />
    <Route path='/detach' element={<DetachWindow  />} />
</Routes>
</BrowserRouter>
    
  
  );
}

export default App;
