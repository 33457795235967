import React, { useState, useEffect }  from 'react';
import List from '@mui/material/List';

import { Link, useLocation } from "react-router-dom";
import '../css/Search.css'
import logo from '../images/Aipalbot-low-shadow-trim.png';
import Form from 'react-bootstrap/Form';
import SearchCard from './SearchCard';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import {pink, red} from "@mui/material/colors";
import {isMobile} from 'react-device-detect';
 function SearchResult() {
  const location =  useLocation();


    const [submitted, setSubmitted] = useState('');
    const [models, setModels] = useState([]);
    const [text, setText] = useState("");
    //const isApple = ['iPhone', 'iPad', 'iPod', 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator',].includes(navigator.platform)
    async function handleSubmit(e) {
        console.log('1');
        e.preventDefault();
        setSubmitted(text);
        setText("");
        console.log('2');
        console.log('text = ' + text);
        const wait = await searchAPI()   
        console.log('6');    
        console.log(wait);  
      }
      function handleChange(e) {
        setText(e.target.value);
      }

      const searchAPI = async () => { 
        let chatModel = {
            word: text
        }
        console.log(chatModel);
        const req = JSON.stringify(chatModel);
        console.log(req);
        console.log('3');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: req
        };
        
        try {
            const response = await fetch('https://aipalbot-chat.azurewebsites.net/search',
         requestOptions);
            const data = await response.json();
            console.log('4');           
            console.log(data)
            setModels(data)
            console.log('5');  
        } catch (e) {
            console.log("unable to parse - fetch chat");
        }
      };

  return (
    <div className="search-box">    
    <div>
    <Form className="d-flex"   onSubmit={handleSubmit}>
<br>
</br>
            <Form.Control
              type="search"
              value={text}
              placeholder=" Search data from millions of robots"
              className="me-2"
              aria-label="Search"
              onChange={handleChange} 
            />
            <span className="buttons">
              <button hidden={isMobile}>
                <ManageSearchRoundedIcon sx={{ color: red[500], fontSize: 40 }}/>
              </button>
            </span>
        </Form>
        previous search: {submitted}
        <br></br>    <br></br>

        <div>
              <a href='https://about.aipalbot.com/'>
           <img src={logo} className="App-logo" alt="Connecting people robotically!"/>
                     </a>
           </div>

      
      

        </div>
   
        <br></br>    <br></br>
        <List sx={{ width: '95%', maxWidth: 390, bgcolor: 'background.paper', padding:'0 1% 0 1%' }}>
          {models&&models.map((model, index) => {
            return (<SearchCard searchItem={model} />)
    //          const {imageUrl,robotName,url,description,title }= model;
    //        return (
    //         <div>
    //  <ListItem alignItems="flex-start">
    //     {/* <ListItemAvatar>
    //       <Avatar alt={robotName} src={imageUrl} />
    //     </ListItemAvatar> */}
    //     <ListItemText
    //       secondary={
    //         <React.Fragment>
    //           <Typography
    //             sx={{ display: 'inline' }}
    //             component="span"
    //             variant="body2"
    //             color="text.primary"
    //           >
            
    //              <Link onClick={showDialog}  to="/"
    //                 state={{
    //                     url: url,
    //                   }} className="card-title">{title}</Link> 
    //            {/* <Link onClick={showDialog} className="card-title">{title}</Link> */}
    //           </Typography>
    //           <br></br>
    //           <div className='card-description'>{description}</div>
              
    //         </React.Fragment>
    //       }
    //       className="card-style"
    //     />
        
    //   </ListItem>
      
    // </div>);
    
})}
        </List>
        </div>
        
  );

 
}


export default SearchResult;