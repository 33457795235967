import React from 'react'
import NewWindow from 'react-new-window'
import {useLocation} from "react-router-dom"
function DetachWindow (props){
   
    const location =  useLocation();
    console.log(location.state.url)
  return (<NewWindow url={location.state.url}>
    <h1>.... 👋</h1>
  </NewWindow>);
}

export default DetachWindow;