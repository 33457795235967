import React, {useState} from 'react';
import logo from '../images/logos/png24.png';
import Form from 'react-bootstrap/Form';
import '../css/Header.css'

function Header() {
    const [data, setData] = useState("")

    const handleChange =(input) => {
        setData(input);
        console.log(input)
        localStorage.setItem('search_context',input)
      };
    return (
      <div className="search-box">    
    
      <Form className="d-flex" >

              <Form.Control
                type="search"
                value={data}
                placeholder=" Search data from millions of robots"
                className="me-2"
                aria-label="Search"
                onChange={handleChange}
              />
          </Form>
          
          <a href='https://about.aipalbot.com/'>
          <div>
             <img src={logo} className="App-logo" alt="Connecting people robotically!"/>
             </div>
             </a>
        
        
          <h6>Welcome to the future of robotically connected search platform</h6>

         
      </div>
      
    
    );
  }
  
  export default Header;