import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
export default function SearchCard({searchItem}) {
  function showDialog() {
    window.open(searchItem.url, "", "width=350,height=520,left=200,top=200")
}
  return (
    <div>
<ListItem alignItems="flex-start">
{/* <ListItemAvatar>
  <Avatar alt={robotName} src={imageUrl} />
</ListItemAvatar> */}
<ListItemText
  secondary={
    <React.Fragment>
      <Typography
        sx={{ display: 'inline' }}
        component="span"
        variant="body2"
        color="text.primary"
      >
    
          <a href='#launch-robot' onClick={showDialog} className="card-title">{searchItem.title}</a>
      </Typography>
      <br></br>
      <div className='card-description'>{searchItem.description}</div>
      
    </React.Fragment>
  }
  className="card-style"
/>

</ListItem>

</div>);

}
